<template>
  <div class="btn">
    <span @click="openDiag">导出试卷</span>
    <!-- <span @click='printPaper(1)'>导出试卷(附解析) </span> -->
    <div>
      <a-modal
        title="导出"
        :visible="exportOptDialog"
        :closable="false"
        @ok="printPaper"
        @cancel="handleClose"
        :centered="true">
        <!-- <template #footer>
          <a-button key="back" @click="handleClose">取 消</a-button>
          <a-button key="submit" type="primary" :loading="loading" @click="printPaper">确 定</a-button>
        </template> -->
        <div style="display: flex;flex-direction: column;">
          <a-checkbox disabled v-model:checked="has_question">包含题目</a-checkbox>
          <a-checkbox style="margin: 0px" v-model:checked="has_explain">包含题目答案、解析</a-checkbox>
          <a-checkbox style="margin: 0px" v-model:checked="show_source">包含题目来源</a-checkbox>
        </div>
      </a-modal>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { exportPaper } from '@/api/exportPaper.js'
import { toRefs } from "@vue/reactivity";
const propsP = defineProps({
  id: {
    type: Number,
    default: null
  }
})

const exportOptDialog = ref(false)
const has_question = ref(true)
const has_explain = ref(false)
const show_source = ref(false)
const loading = ref(false)
const { id } = toRefs(propsP)

const base64ToBlob = (base64) => {
  const byteCharacters = atob(base64);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: 'application/octet-stream' });
};

// 打印试卷
const printPaper = async () => {
  loading.value = true
  const data = await exportPaper({
    user_paper_id: id.value,
    detail: +has_explain.value,
    show_source: +show_source.value,
  })
  let i = ''
  if (+has_explain.value == 0) {
    i = "无解析"
  } else {
    i = '附解析'
  }
  
  const blob = base64ToBlob(data.data.base64Content)
  const a = document.createElement("a")
  a.href = window.URL.createObjectURL(blob)
  a.download = `${id.value}_${i}.pdf`
  a.click()
  loading.value = false
  exportOptDialog.value = false
}
const openDiag = () => {
  exportOptDialog.value = true
}
const handleClose = () => {
  exportOptDialog.value = false
}
</script>

<style lang='scss' scoped>
.btn {
  background: #f6f6f6;
  margin-bottom: 20rem;
  span {
    font-size: 20rem;
    margin-left: 30rem;
    padding: 10rem 20rem;
    border: 1px solid #2196f3;
    border-radius: 25rem;
    color: #2196f3;
    cursor: pointer;
  }
}
</style>