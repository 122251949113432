<template>
  <div v-if="timeDialogVisible">
    <a-modal v-model:visible="timeDialogVisible"
             :maskClosable="false"
             :bodyStyle="bodyStyle"
             :closable="false"
             :footer="null"
             centered
             width="500rem">

      <template #title>
        <div v-if="editTime" class="title">
          请输入做题时长（分钟）
        </div>
      </template>
      <a-input v-if="editTime" type="number" v-model:value="time" placeholder=""></a-input>
      <div v-else style="display: flex;justify-content: center;font-size: 24rem;font-weight: 500;">
        <span>已用时间：{{leftTime}} / {{totalTime}} 分钟</span>
      </div>

      <div v-if="editTime" class="btn_warp">
        <div @click="handleClose">
          取消
        </div>
        <div @click="setTimeSubmit">
          开始训练
        </div>
      </div>
      <div v-else class="btn_warp">
        <div @click="handleClose">确定</div>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  data () {
    return {
      time: 0,
      timeDialogVisible: false,
      editTime: false,
      leftTime: 0,
      totalTime: 0,
      bodyStyle: {
        padding: "0 ",
      }
    }
  },
  methods: {
    setTimeSubmit () {
      this.$parent.setTimeSubmit(this.time)
      this.timeDialogVisible = false
      this.$message.success('设置成功')
    },
    handleClose () {
      this.timeDialogVisible = false
    },
  }
}
</script>

<style lang="scss" scoped>
.btn_warp {
  height: 70rem;
  border-top: 1px solid #e5e5e5;
  display: flex;
  // position: absolute;
  bottom: 0;
  width: 100%;
  div {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:nth-child(1) {
      font-size: 24rem;
      font-weight: bold;
      color: #999999;
      border-right: 1px solid #e5e5e5;
    }
    &:nth-child(2) {
      font-size: 24rem;
      font-weight: bold;
      color: #2196f3;
    }
  }
}

.ant-form {
  padding: 0 20rem;
}
:deep .ant-form-item-label > label {
  font-size: 24rem;
  font-weight: 500;
  color: #333333;
}

:deep .ant-input {
  font-size: 24rem;
  font-weight: 500;
  color: #999999;
}

.title {
  font-size: 24rem !important;
}
</style>